import { FunctionComponent, PropsWithChildren, useContext, useState, useEffect, useCallback } from "react";

import { FormType, TicketingFormCategory, Utils } from "@simplyk/common";
import { UseFormSetValue } from "react-hook-form";
import { v4 } from "uuid";

import { getEvent } from "../components/PaymentProcessor/helper";
import { TicketingFormCategoryFeatures } from "../constants/TicketingFormCategory";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import {
  FrontendTicketingContext,
  FrontendTicketingContextValues,
  FullRateError,
  MembershipRenewalBuyerInfo,
} from "../contexts/FrontendTicketingContext";
import { useTranslate } from "../hooks/useTranslate";

import { AmplitudeEvents } from "@/constants/amplitude";
import { OccurrenceWithRatesObject, DiscountObject, OrganizationObject } from "@/gql-types";
import { concatExistingFullRatesWithNewError } from "@/helpers/rates";
import { isTicketingFull, hasNoDate } from "@/helpers/ticketing";
import { trpc } from "@/helpers/trpc";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";
import { useTicketingTranslationField } from "@/hooks/useDonationTranslation";
import { useIsOnboarding } from "@/hooks/useIsOnboarding";
import { TicketingPaymentInput } from "@/types/ticketing";
import { RateOutput, TicketingOutput } from "@/types/trpc";

interface TicketingProviderProps {
  ticketing: TicketingOutput;
}

export const TicketingFrontendProvider: FunctionComponent<PropsWithChildren<TicketingProviderProps>> = ({
  children,
  ticketing,
}) => {
  const { t } = useTranslate();
  const { isPreview } = useContext(FrontendFormContext);
  // this fills the rates of the ticketing in preview mode in case the ticketing has a date but the user has not filled the rates yet
  if (isPreview && (!ticketing.rates || !ticketing.rates.length)) {
    ticketing.rates = [
      {
        amount: 2500,
        id: v4(),
        maximumToBuy: 10,
        photoUrls: [TicketingFormCategory.Custom, TicketingFormCategory.Shop, TicketingFormCategory.Auction].includes(
          ticketing.formCategory
        )
          ? ["/images/previewPlaceholders/item.png"]
          : null,
        rateFields: [
          {
            id: v4(),
            locale: ticketing.locale,
            title: t("dashboard", TicketingFormCategoryFeatures[ticketing.formCategory].defaultRateName),
            description: t("dashboard", "ticketingForm.preview.rateDescription"),
          },
        ],
        sortIndex: 0,
      },
    ] as RateOutput[];
  }
  const { data: getActiveTicketingOccurrences, isLoading: isOccurrencesWithRatesLoading } =
    trpc.form_getActiveTicketingOccurrences.useQuery({
      ticketingId: ticketing.id,
    });

  const occurrencesWithRates = getActiveTicketingOccurrences as OccurrenceWithRatesObject[] | undefined;

  // this fills the rates of the empty occurrences in preview mode in case the ticketing has a date but the user has not filled the rates yet
  if (isPreview && occurrencesWithRates) {
    occurrencesWithRates.forEach((occurrenceWithRates) => {
      if (occurrenceWithRates.rates.length === 0 && ticketing.rates) {
        occurrenceWithRates.rates = ticketing.rates?.map((rate) => ({
          rateId: rate.id,
          remainingTickets: rate.maximumToBuy,
          soldTicketsCount: 0,
        }));
      }
    });
  }

  const hasOnlyOneDate = occurrencesWithRates?.length === 1 && Boolean(occurrencesWithRates[0].occurrence);
  const hasADate = !hasNoDate(occurrencesWithRates);

  const occurrenceWithRatesMapById = Utils.arrayToKeyMap(
    occurrencesWithRates || [],
    (occurrenceWithRates) => (occurrenceWithRates.occurrence?.id || null) as string
  );

  const defaultSelectedOccurrenceId = hasOnlyOneDate ? occurrencesWithRates?.[0]?.occurrence?.id || null : null;

  const [selectedOccurrenceId, setSelectedOccurrenceId] = useState<string | null>(defaultSelectedOccurrenceId);

  useEffect(() => {
    if (defaultSelectedOccurrenceId) {
      setSelectedOccurrenceId(defaultSelectedOccurrenceId);
    }
  }, [defaultSelectedOccurrenceId]);

  // the null key is used when the ticketing is not subject to occurrences
  const selectedOccurrenceWithRates = occurrenceWithRatesMapById[selectedOccurrenceId || "null"] || undefined;

  const { data: hasDiscounts } = trpc.form_getHasRemainingDiscountsByTicketingId.useQuery({
    ticketingId: ticketing.id,
    occurrenceId: selectedOccurrenceId,
  });

  const { data: ticketingQuestionsData } = trpc.form_getTicketingQuestions.useQuery(
    {
      ticketingId: ticketing.id,
      occurrenceId: selectedOccurrenceId,
    },
    {
      retry: 3,
    }
  );

  const selectedOccurrence = selectedOccurrenceWithRates?.occurrence;

  const [ticketingIsFull, setTicketingIsFull] = useState(
    isTicketingFull({ occurrencesWithRates, selectedOccurrenceId, formCategory: ticketing.formCategory })
  );
  const [ticketingDescriptionIsOpen, setTicketingDescriptionIsOpen] = useState(false);
  const [buyerDrawerIsOpen, setBuyerDrawerIsOpen] = useState(false);
  const [fullRatesReturnByOrder, setFullRatesReturnByOrder] = useState<FullRateError[]>([]);
  const [remainingTicketingSeatsReturnByOrder, setRemainingTicketingSeatsReturnByOrder] = useState<null | number>(null);
  const [validDiscount, setValidDiscount] = useState<DiscountObject | null>(null);
  const [remainingTicketingSeats, setRemainingTicketingSeats] = useState<number>(Infinity);

  const isMembershipV2 = ticketing.formCategory === TicketingFormCategory.MembershipV2;

  const { isOnboarding } = useIsOnboarding();
  const { logAmplitudeEvent } = useAmplitude();
  const { ticketingField } = useTicketingTranslationField(ticketing);

  const openTicketingDescription = () => {
    logAmplitudeEvent(AmplitudeEvents.DonorFormDescriptionOpened, {
      formType: FormType.Ticketing,
      formId: ticketing.id,
      organizationCountry: ticketing.organization?.country,
    });
    setTicketingDescriptionIsOpen(true);
    window.scrollTo(0, 0);
  };

  const closeTicketingDescription = () => {
    setTicketingDescriptionIsOpen(false);
    window.scrollTo(0, 0);
  };

  const toggleBuyerDrawer = useCallback(
    (setValue: UseFormSetValue<TicketingPaymentInput>) => () => {
      window.scrollTo(0, 0);
      if (buyerDrawerIsOpen && isMembershipV2 && ticketing.rates) {
        new Array<number>(ticketing.rates.length).fill(0).forEach((_, idx) => {
          setValue(`ticketsPurchased.${idx}.purchasedNumber`, 0);
        });
      }
      setBuyerDrawerIsOpen((prev) => !prev);
    },
    [buyerDrawerIsOpen, isMembershipV2, ticketing.rates]
  );

  const fallBackToTicketingContainerOnFullTicketing = useCallback(
    (setValue: UseFormSetValue<TicketingPaymentInput>) => {
      toggleBuyerDrawer(setValue)();
      setTicketingIsFull(true);
    },
    [toggleBuyerDrawer]
  );

  const fallBackToTicketsDrawerOnOverpassedRatesSeats = useCallback(
    (newFullRates: FullRateError[], setValue: UseFormSetValue<TicketingPaymentInput>) => {
      toggleBuyerDrawer(setValue)();
      setFullRatesReturnByOrder((prevFullRates) => {
        return concatExistingFullRatesWithNewError(prevFullRates, newFullRates);
      });
    },
    [toggleBuyerDrawer]
  );

  const fallBackToTicketsDrawerOnOverPassedTicketingSeats = useCallback(
    (remainingTicketingSeats: number, setValue: UseFormSetValue<TicketingPaymentInput>) => {
      toggleBuyerDrawer(setValue)();
      setRemainingTicketingSeatsReturnByOrder(remainingTicketingSeats);
    },
    [toggleBuyerDrawer]
  );

  const fallBackToTicketingContainerOnNoTicketSelected = useCallback(
    (setValue: UseFormSetValue<TicketingPaymentInput>) => {
      toggleBuyerDrawer(setValue)();
    },
    [toggleBuyerDrawer]
  );

  useEffect(() => {
    if (!hasADate && occurrencesWithRates?.length === 1) {
      setTicketingIsFull(
        isTicketingFull({ occurrencesWithRates, selectedOccurrenceId: undefined, formCategory: ticketing.formCategory })
      );
    }
  }, [hasADate, occurrencesWithRates, ticketing.formCategory]);

  useEffect(() => {
    const remainingTicketsOnTicketing =
      ticketing?.seats && selectedOccurrence
        ? ticketing.seats -
          selectedOccurrenceWithRates.rates.reduce((acc, value) => acc + (value.soldTicketsCount || 0), 0)
        : Infinity;
    setRemainingTicketingSeats(
      Math.min(
        remainingTicketingSeatsReturnByOrder ?? Infinity,
        ticketing?.seats ?? Infinity,
        remainingTicketsOnTicketing
      )
    );
  }, [ticketing.seats, selectedOccurrenceWithRates, remainingTicketingSeatsReturnByOrder, selectedOccurrence]);

  useEffect(() => {
    if (selectedOccurrenceId) {
      setTicketingIsFull(
        isTicketingFull({ occurrencesWithRates, selectedOccurrenceId, formCategory: ticketing.formCategory })
      );
    }
  }, [selectedOccurrenceId, ticketing, occurrencesWithRates]);

  const [membershipRenewalBuyerInfo, setMembershipRenewalBuyerInfo] = useState<MembershipRenewalBuyerInfo | undefined>(
    undefined
  );

  const questions = ticketingQuestionsData || [];

  const values: FrontendTicketingContextValues = {
    openTicketingDescription,
    closeTicketingDescription,
    ticketingDescriptionIsOpen,
    ticketingIsFull,
    buyerDrawerIsOpen,
    toggleBuyerDrawer,
    fallBackToTicketingContainerOnFullTicketing,
    fallBackToTicketsDrawerOnOverpassedRatesSeats,
    fallBackToTicketsDrawerOnOverPassedTicketingSeats,
    fallBackToTicketingContainerOnNoTicketSelected,
    fullRatesReturnByOrder,
    remainingTicketingSeatsReturnByOrder,
    remainingTicketingSeats,
    hasDiscountCodes: Boolean(hasDiscounts),
    validDiscount,
    setValidDiscount,
    ticketing,
    ticketingField,
    organization: ticketing.organization as OrganizationObject,
    questions,
    featureFlag: ticketing.ticketingFeatureFlagTreatment || { isPaymentByPadAllowed: false },
    registrationIncreaseThermometer: Boolean(ticketing.donationForm?.registrationIncreaseThermometer),
    isOnboarding,
    selectedOccurrenceWithRates,
    setSelectedOccurrenceId,
    selectedOccurrenceId,
    occurrencesWithRates: Object.values(occurrenceWithRatesMapById),
    hasADate,
    isOccurrencesWithRatesLoading,
    hasOnlyOneDate,
    ticketingIsClosed: Boolean(
      ticketing.isArchived || ticketing.closed || selectedOccurrence?.closed || occurrencesWithRates?.length === 0
    ),
    isCampaignRegistration: Boolean(ticketing.donationForm?.campaignId),
    donationForm: ticketing.donationForm || undefined,
    event: ticketingField ? getEvent({ ticketing, ticketingField, selectedOccurrence }) : undefined,
    isAuction: ticketing.formCategory === TicketingFormCategory.Auction,
    membershipRenewalBuyerInfo,
    setMembershipRenewalBuyerInfo,
  };

  return <FrontendTicketingContext.Provider value={values}>{children}</FrontendTicketingContext.Provider>;
};
