import { Emptyable, Nullable } from "@simplyk/common";

import { OccurrenceWithRatesObject, TicketingFormCategory, TicketingObject } from "../gql/gql-types";

export const isMultidateTicketing = (formCategory: Emptyable<TicketingFormCategory>, occurrencesCount: number) => {
  return isEligibleMultidateForm(formCategory) && occurrencesCount > 1;
};

export const isEligibleMultidateForm = (formCategory?: Nullable<TicketingFormCategory>) =>
  formCategory === TicketingFormCategory.Event ||
  formCategory === TicketingFormCategory.Lottery ||
  formCategory === TicketingFormCategory.Custom;

export const getTicketingTitle = (title: string = "", isRegistration: boolean, suffix: string) => {
  if (isRegistration) {
    return title + suffix;
  }

  return title;
};

export const hasNoDate = (occurrencesWithRates: Nullable<OccurrenceWithRatesObject[]> | undefined) => {
  return occurrencesWithRates && occurrencesWithRates.length === 1 && !occurrencesWithRates[0]?.occurrence;
};

type IsTicketingFull = (_: {
  occurrencesWithRates?: Nullable<OccurrenceWithRatesObject[]>;
  selectedOccurrenceId?: Nullable<string>;
  formCategory?: TicketingFormCategory;
}) => boolean;

export const isTicketingFull: IsTicketingFull = ({ occurrencesWithRates, selectedOccurrenceId, formCategory }) => {
  if (formCategory === TicketingFormCategory.Auction) {
    return false;
  }
  // Without date
  if (hasNoDate(occurrencesWithRates) && occurrencesWithRates?.length) {
    return (
      Boolean(occurrencesWithRates[0].rates.length) &&
      !!occurrencesWithRates[0].rates.every((rate) => rate.remainingTickets === 0)
    );
  }

  // With date
  if (occurrencesWithRates && occurrencesWithRates.length && selectedOccurrenceId) {
    const selectedOccurrenceWithRates = occurrencesWithRates.find(
      (occurrencesWithRatesItem) => occurrencesWithRatesItem?.occurrence?.id === selectedOccurrenceId
    );
    return !!selectedOccurrenceWithRates?.rates.every((rate) => rate.remainingTickets === 0);
  }

  return false;
};

type IsTicketingClose = (_: {
  ticketing: TicketingObject;
  occurrencesWithRates?: Nullable<OccurrenceWithRatesObject[]>;
}) => boolean;
export const isTicketingClosed: IsTicketingClose = ({ ticketing, occurrencesWithRates }) =>
  Boolean(
    ticketing.closed ||
      occurrencesWithRates?.length === 0 ||
      occurrencesWithRates?.every((occurenceWithRates) => occurenceWithRates.occurrence?.closed)
  );
