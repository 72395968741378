/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from "react";

import {
  getCurrencyFromCountry,
  getDollarValue,
  getField,
  getIsoLocaleFromCountry,
  getTicketingLink,
  OrganizationCountry,
} from "@simplyk/common";

import { useTicketingTranslationField } from "../../hooks/useDonationTranslation";
import { useHeadDescription } from "../../hooks/useHeadDescription";

import { ZeffyHead } from "./ZeffyHead";

import { TicketingOutput } from "@/types/trpc";

interface TicketingHeadProps {
  ticketing: TicketingOutput;
  country?: OrganizationCountry;
}

export const TicketingHead: FC<TicketingHeadProps> = ({ ticketing, country }) => {
  const { ticketingField } = useTicketingTranslationField(ticketing as TicketingOutput);
  const description = useHeadDescription(ticketingField?.description || "");

  const frontendUrl = process.env.NEXT_PUBLIC_FRONTEND_URL as string;
  const url = `${frontendUrl}${getTicketingLink(ticketing.path, getIsoLocaleFromCountry(country, ticketing.locale))}`;
  const currency = getCurrencyFromCountry(ticketing.organization?.country);

  const occurrenceAfterToday = ticketing.occurrencesWithRates?.find(
    (occurrence) => occurrence.occurrence?.startUtc && new Date(occurrence.occurrence.startUtc) > new Date()
  );
  const startDate =
    occurrenceAfterToday?.occurrence?.startUtc || ticketing.occurrencesWithRates?.[0]?.occurrence?.startUtc;

  const fallbackProductImage = frontendUrl + "/images/zeffy-logo.png";

  const isEvent = Boolean(startDate);
  const isProduct = !isEvent;

  return (
    <ZeffyHead
      description={ticketingField?.description}
      image={ticketing.bannerUrl || ticketing.logoUrl || undefined}
      title={ticketingField?.title}
      url={url}
      video={ticketing.bannerVideoUrl || undefined}
    >
      {ticketing.rates?.length && ticketing.rates?.length > 0 && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": isEvent ? "Event" : "Product",
            name: ticketingField?.title,
            eventAttendanceMode: ticketing.address
              ? "https://schema.org/OfflineEventAttendanceMode"
              : "https://schema.org/OnlineEventAttendanceMode",
            location: ticketing.address
              ? {
                  "@type": "Place",
                  name: ticketing.address,
                  address: ticketing.address,
                }
              : {
                  "@type": "VirtualLocation",
                  url,
                },
            startDate,
            image: ticketing.bannerUrl || ticketing.logoUrl || (isProduct ? fallbackProductImage : undefined),
            offers: ticketing.rates?.map((rate) => {
              return {
                "@type": "Offer",
                url,
                price: getDollarValue(rate.amount),
                priceCurrency: currency.toUpperCase(),
                name: ticketingField ? getField(rate.rateFields, ticketingField.locale)?.title : "",
                description: ticketingField ? getField(rate.rateFields, ticketingField.locale)?.description : "",
              };
            }),
            description,
            organizer: {
              "@type": "Organization",
              name: ticketing.organization?.name,
            },
          })}
        </script>
      )}
    </ZeffyHead>
  );
};
