import { Locales, TicketingFormCategory } from "@simplyk/common";

const ETicketKnowMoreLinkMembershipFr = process.env.NEXT_PUBLIC_ZEFFY_E_TICKETS_URL_FR;
const ETicketKnowMoreLinkMembershipEn = process.env.NEXT_PUBLIC_ZEFFY_E_TICKETS_URL_EN;

export const TicketingFormCategoryFeatures = {
  [TicketingFormCategory.Custom]: {
    title: "ticketingForm.default.title.custom",
    defaultRateName: "ticketingForm.default.defaultRateName.custom" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.custom",
    hasDate: true,
    hasLocation: true,
    hasReminder: true,
    hasTicketingSeats: true,
    hasETicket: true,
    displayLearnMoreOnETicket: true,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
  [TicketingFormCategory.Event]: {
    title: "ticketingForm.default.title.event",
    defaultRateName: "ticketingForm.default.defaultRateName.event" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.event",
    hasDate: true,
    hasLocation: true,
    hasReminder: true,
    hasTicketingSeats: true,
    hasETicket: true,
    displayLearnMoreOnETicket: true,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
  [TicketingFormCategory.Membership]: {
    title: "ticketingForm.default.title.membership",
    defaultRateName: "ticketingForm.default.defaultRateName.membership" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.membership",
    hasDate: false,
    hasLocation: false,
    hasReminder: false,
    hasTicketingSeats: false,
    hasETicket: true,
    displayLearnMoreOnETicket: false,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
  [TicketingFormCategory.MembershipV2]: {
    title: "ticketingForm.default.title.membership",
    defaultRateName: "ticketingForm.default.defaultRateName.membershipV2" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.membership",
    hasDate: false,
    hasLocation: false,
    hasReminder: false,
    hasTicketingSeats: false,
    hasETicket: true,
    displayLearnMoreOnETicket: false,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
  [TicketingFormCategory.Lottery]: {
    title: "ticketingForm.default.title.lottery",
    defaultRateName: "ticketingForm.default.defaultRateName.lottery" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.lottery",
    hasDate: true,
    hasLocation: false,
    hasReminder: false,
    hasTicketingSeats: true,
    hasETicket: true,
    displayLearnMoreOnETicket: true,
    eTicketKnowMoreLink: {
      [Locales.FR]: process.env.NEXT_PUBLIC_ZEFFY_LOTTERY_URL_FR,
      [Locales.EN]: process.env.NEXT_PUBLIC_ZEFFY_LOTTERY_URL_EN,
      [Locales.ES]: process.env.NEXT_PUBLIC_ZEFFY_LOTTERY_URL_EN,
    },
  },
  [TicketingFormCategory.Shop]: {
    title: "ticketingForm.default.title.shop",
    defaultRateName: "ticketingForm.default.defaultRateName.shop" as const,
    eTicketSubtitle: "ticketingForm.default.eTicketSubtitle.shop",
    hasDate: false,
    hasLocation: false,
    hasReminder: false,
    hasTicketingSeats: false,
    hasETicket: false,
    displayLearnMoreOnETicket: true,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
  [TicketingFormCategory.Auction]: {
    title: "ticketing.auctionTitlePlaceholder",
    defaultRateName: "ticketingForm.default.defaultRateName.auction" as const,
    eTicketSubtitle: "",
    hasDate: true,
    hasLocation: true,
    hasReminder: true,
    hasTicketingSeats: false,
    hasETicket: false,
    displayLearnMoreOnETicket: true,
    eTicketKnowMoreLink: {
      [Locales.FR]: ETicketKnowMoreLinkMembershipFr,
      [Locales.EN]: ETicketKnowMoreLinkMembershipEn,
      [Locales.ES]: ETicketKnowMoreLinkMembershipEn,
    },
  },
};
